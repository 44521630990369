<template>
  <div>
    <v-btn
        color="primary white--text"
        class="mr-5 mb-2"
        @click="dialog = true">
      {{ btn_title }}
      <v-icon right>{{ btn_icon }}</v-icon>
    </v-btn>
    <v-dialog
        v-model="dialog"
        persistent
        width="1000"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ titel }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
            <v-data-table
                dense
                :headers="headers"
                :items="content"
                :search="search"
                v-model="selected"
                :single-select="singleSelect"
                show-select
                hide-default-footer
                class="elevation-1 mx-5"
            >
              <template v-slot:top>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Suchen"
                  class="mx-4"
              ></v-text-field>
              </template>
            </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialog = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="getSelection"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'verbaende',
  props: {
    titel: String,
    btn_title: String,
    btn_icon: String,
    content: Array,
    headers: Array
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    loading: false,
    search:'',
    selected: [],
    singleSelect: true
  }),

  computed: {},

  mounted() {
  },
  methods: {
    getSelection(){
      this.dialog = false;
      this.$emit('GET_SELECTION', this.selected[0]);
    }
  }
}
</script>

<style scoped>

</style>
